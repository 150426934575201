@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');


.resume-background{
  background-color: lightblue;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

}

.left-component,
.right-component {
  background-color: rgba(0, 0, 0, 0);
  min-height: 1000px;
}

.left-component {
  padding: 20px;
}

.right-component {
  padding: 40px;
}

.container-max {
  max-width: 1000px;
  margin: 0px auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.about .box .year_company .company_icon a img {

  max-width: 80%;
  max-height: 60%;

}

.contactInfo.education ul li .company_icon a img {

  max-width: 80%;
  max-height: 60%;

}

ul {
  margin: 0 !important;
  padding: 0 !important;
}

@media (max-width: 992px) {

  .container-max {
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .left-component,
  .right-component {
    width: 100%;
    min-height: initial;
    padding-left: 40px;
    padding-right: 40px;
  }

  .about .box .year_company .company_icon a img {

    max-width: 100px;
    max-height: 100px;

  }

  .contactInfo.education ul li .company_icon a img {

    max-width: 200px;
    max-height: 100px;

  }
}

@media (max-width: 600px) {
  
  .about .box {
    flex-direction: column;
  }

  .about .box .year_company {
    min-width: 150px;
    margin-bottom: 5px;
  }

  .about.skills .box .skill_type {
    margin-bottom: 5px;
  }
}

@media (max-width: 500px) {
  .left-component {
    padding: 10px;
  }
  
  .right-component {
    padding: 10px;
  }
  .container-max {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}