.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
}
.general-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner-top-text {
    font-family: "Arial", sans-serif;
    font-size: 30px;
    font-weight: bold;
}

.banner-bottom-text {
    font-family: "Times New Roman", serif;
    font-size: 20px;
}

@media (max-width: 500px) {
    .banner-top-text {
        font-size: 20px;
    }
    
    .banner-bottom-text {
        font-size: 16px;
    }
}