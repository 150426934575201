.grocery-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
}

.grocery-image {
  height: 60px;
  width: auto;
  /* border-bottom: 1px solid rgba(100, 100, 100, 0.6); */
}

.grocery-text {
  font-size: 26px;
  font-family: Georgia, serif
}
/* .grocery-logo {
  display: flex;
  align-items: center;
}

.grocery-image {
  height: 60px;
  width: auto;
}

.grocery-text {
  margin-left: 10px; 
  flex-grow: 1;
  display: flex;
  align-items: center;
} */