.centered-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .centered-image {
    max-width: 100%;
    max-height: 100%;
  }
  