.contactInfo.education h3 {
    padding-left: 32px;
}
.contactInfo.education li
{
    margin-bottom: 15px;
}

.contactInfo.education h6
{
    color: #fff;
    font-weight: 500;

}

.contactInfo.education ul li .h7
{
    color: #03a9f4;
    font-weight: 500;
    font-size: 14px;
}

.contactInfo.education h4:nth-child(2)
{
    color: #fff;
    font-weight: 500;
}

.contactInfo.education h5
{
    color: #fff;
    font-weight: 600;
}
