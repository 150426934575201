.a {
    text-decoration: none;
}

.btn-fixed-size {
    width: 100px !important;

}

.search-bar-border-bottom {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.6);
}

.grocery-flyer-search-footer{
    font-size: 10px;
    margin: 10px;
}

@media (max-width: 767.98px) {
  }

@media (max-width: 576px) {
    .search-bar-border-bottom {
        border: hidden;
    }
    .btn-fixed-size, .input-text {
        width: auto !important;
        margin: 10px;
        /* Reset the width for extra small screens */
    }
}