.grocery-flyer-search{
  color: #add8e6;
  font-weight: bold;
}

.navbar-brand {
  margin-left: 30px;

  /* Example margin value */
}

.navbar-brand-link {
  text-decoration: none;
}

@media (max-width: 992px) {
  .ms-auto {
    margin-left: 25px !important;
  }
}