.product-link {
  text-decoration: none;
  color: inherit;
}

.product-link:hover {
  font-weight: 600;
}


.product-link:hover * {
  color: inherit;
}


.product-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.image-container {
  flex-grow: 1;
  overflow: hidden;
  text-align: center;
}


.image-container img {
  width: 100%;
  height: 180px;
  object-fit: contain
    /* Clips the image to fit the container */
}

.product-info {
  margin-top: 10px;
  max-width: 100%;
  /* background-color: aqua; */
  text-align: center;
  white-space: normal;
  word-break: break-word;
}

.product-name {
  /* background-color: red; */
  max-width: 100%;
  height: 50px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 6px;
}

.prev-price,
.curr-price {
  max-width: 100%;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.curr-price {
  color: red;
  font-weight: 600;
  font-size: 20px;
  height: 30px;
  /* background-color: darkgreen; */
}

.prev-price {
  height: 20px;
  font-size: 15px;
  /* background-color: blue; */
  text-decoration: line-through;
}


.status {
  /* background-color: gray; */
  margin-top: 8px;
}

.grey-status {
  color: grey;
}