@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;



}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  
  /* padding: 20px; */
  /* margin: 50px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 500px) {
  .App {
  
    /* padding: 20px; */
    /* margin: 15px; */
  }
}
.left-container {
    position: relative;
    background-color: #003147;
    padding: 20px;
}

.title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
}
.title-content {
    text-align: center;
}

.profileText {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}


.profileText h2 {
    color: #fff;
    font-size: 1.5em;
    margin-top: 20px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    line-height: 1.4em;
}

.profileText h2 span {
    font-size: 0.8em;
    font-weight: 400;
}

.profileText .imgBx {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
}

.profileText .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contactInfo {
    padding-top: 40px;
}

.contactInfo h5 {
    padding-left: 0px;
}

.contactInfo ul
{
    position: relative;
}

.contactInfo ul li
{
    position: relative;
    list-style: none;
    margin: 10px 0;
    /* cursor: pointer; */
}

.contactInfo ul li .icon
{
    display: inline-block;
    width: 30px;
    font-size: 18px;
    color: #03a9f4;
}

.contactInfo ul li span
{
    font-weight: 400;
    color: #fff;
}

.contactInfo ul li a
{
    font-weight: 300;
    color: #fff;

}

.contactInfo ul li a span
{
    
    text-decoration: none;
}

.contactInfo.education h3 {
    padding-left: 32px;
}
.contactInfo.education li
{
    margin-bottom: 15px;
}

.contactInfo.education h6
{
    color: #fff;
    font-weight: 500;

}

.contactInfo.education ul li .h7
{
    color: #03a9f4;
    font-weight: 500;
    font-size: 14px;
}

.contactInfo.education h4:nth-child(2)
{
    color: #fff;
    font-weight: 500;
}

.contactInfo.education h5
{
    color: #fff;
    font-weight: 600;
}

.contactInfo.language {
    color: #fff;
    font-weight: 300;
}
.right-container {
    background-color: #fff;
}

.title2
{
    color: #003147;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.about
{
    margin-bottom: 50px;
}

.about:last-child
{
    margin-bottom: 0;
}


.about .box
{
    display: flex;
    flex-direction: row;
    margin: 20px 0;
}

.about .box .year_company
{
    min-width: 180px;
}

.about .box .year_company .company_icon a img
{

    max-width: 80%;
    max-height: 60%;

}


.about .box .year_company h5
{
    text-transform: uppercase;
    color: #848c90;
    font-weight: 600;
    font-size: 14px;
}
.about .box .text h4
{
    text-transform: uppercase;
    color: #2a7da2;
    font-size: 16px;
    font-weight: 700 !important;
}

.about .skills .box .skill_type
{
    margin-right: 10px;
    min-width: 150px;
}



.right-side-title {
    display: block;
    font-size: 1.5em;
    -webkit-margin-before: 0.83em;
            margin-block-start: 0.83em;
    -webkit-margin-after: 0.83em;
            margin-block-end: 0.83em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
  }

.skill_box {
    margin: 0 !important;
}
.ray_h5 {
    display: block;
    font-size: 0.83em;
    -webkit-margin-before: 1.67em;
            margin-block-start: 1.67em;

    -webkit-margin-start: 0px;

            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: 800;
}

.ray_h4 {
    display: block;
    -webkit-margin-before: 1.33em;
            margin-block-start: 1.33em;

    -webkit-margin-start: 0px;

            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: 800;
}
.resume-background{
  background-color: lightblue;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

}

.left-component,
.right-component {
  background-color: rgba(0, 0, 0, 0);
  min-height: 1000px;
}

.left-component {
  padding: 20px;
}

.right-component {
  padding: 40px;
}

.container-max {
  max-width: 1000px;
  margin: 0px auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.about .box .year_company .company_icon a img {

  max-width: 80%;
  max-height: 60%;

}

.contactInfo.education ul li .company_icon a img {

  max-width: 80%;
  max-height: 60%;

}

ul {
  margin: 0 !important;
  padding: 0 !important;
}

@media (max-width: 992px) {

  .container-max {
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .left-component,
  .right-component {
    width: 100%;
    min-height: initial;
    padding-left: 40px;
    padding-right: 40px;
  }

  .about .box .year_company .company_icon a img {

    max-width: 100px;
    max-height: 100px;

  }

  .contactInfo.education ul li .company_icon a img {

    max-width: 200px;
    max-height: 100px;

  }
}

@media (max-width: 600px) {
  
  .about .box {
    flex-direction: column;
  }

  .about .box .year_company {
    min-width: 150px;
    margin-bottom: 5px;
  }

  .about.skills .box .skill_type {
    margin-bottom: 5px;
  }
}

@media (max-width: 500px) {
  .left-component {
    padding: 10px;
  }
  
  .right-component {
    padding: 10px;
  }
  .container-max {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.a {
    text-decoration: none;
}

.btn-fixed-size {
    width: 100px !important;

}

.search-bar-border-bottom {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.6);
}

.grocery-flyer-search-footer{
    font-size: 10px;
    margin: 10px;
}

@media (max-width: 767.98px) {
  }

@media (max-width: 576px) {
    .search-bar-border-bottom {
        border: hidden;
    }
    .btn-fixed-size, .input-text {
        width: auto !important;
        margin: 10px;
        /* Reset the width for extra small screens */
    }
}
.horizontal-scroll,
.horizontal-scroll-border-bottom {
  margin: 30px;
}

.horizontal-scroll {
  overflow-x: scroll;
  overflow-y: hidden; /* Added to prevent vertical scrolling */
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.horizontal-scroll-content {
  display: inline-flex;
  padding-right: 20px;
}

.horizontal-scroll-border-bottom {
  border-bottom: 1px solid rgba(100, 100, 100, 0.6);
}

/* The scrollbar styles (optional) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.ProductItems {
    width: 240px;
    height: 350px;
    margin: 5px;



}

.ProductItemListContainer {
    display: inline-flex;
}

.ProductItemListLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}
.product-link {
  text-decoration: none;
  color: inherit;
}

.product-link:hover {
  font-weight: 600;
}


.product-link:hover * {
  color: inherit;
}


.product-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.image-container {
  flex-grow: 1;
  overflow: hidden;
  text-align: center;
}


.image-container img {
  width: 100%;
  height: 180px;
  object-fit: contain
    /* Clips the image to fit the container */
}

.product-info {
  margin-top: 10px;
  max-width: 100%;
  /* background-color: aqua; */
  text-align: center;
  white-space: normal;
  word-break: break-word;
}

.product-name {
  /* background-color: red; */
  max-width: 100%;
  height: 50px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 6px;
}

.prev-price,
.curr-price {
  max-width: 100%;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.curr-price {
  color: red;
  font-weight: 600;
  font-size: 20px;
  height: 30px;
  /* background-color: darkgreen; */
}

.prev-price {
  height: 20px;
  font-size: 15px;
  /* background-color: blue; */
  text-decoration: line-through;
}


.status {
  /* background-color: gray; */
  margin-top: 8px;
}

.grey-status {
  color: grey;
}
.grocery-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
}

.grocery-image {
  height: 60px;
  width: auto;
  /* border-bottom: 1px solid rgba(100, 100, 100, 0.6); */
}

.grocery-text {
  font-size: 26px;
  font-family: Georgia, serif
}
/* .grocery-logo {
  display: flex;
  align-items: center;
}

.grocery-image {
  height: 60px;
  width: auto;
}

.grocery-text {
  margin-left: 10px; 
  flex-grow: 1;
  display: flex;
  align-items: center;
} */
.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
}
.general-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner-top-text {
    font-family: "Arial", sans-serif;
    font-size: 30px;
    font-weight: bold;
}

.banner-bottom-text {
    font-family: "Times New Roman", serif;
    font-size: 20px;
}

@media (max-width: 500px) {
    .banner-top-text {
        font-size: 20px;
    }
    
    .banner-bottom-text {
        font-size: 16px;
    }
}
.grocery-flyer-search{
  color: #add8e6;
  font-weight: bold;
}

.navbar-brand {
  margin-left: 30px;

  /* Example margin value */
}

.navbar-brand-link {
  text-decoration: none;
}

@media (max-width: 992px) {
  .ms-auto {
    margin-left: 25px !important;
  }
}
.website-log{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centered-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .centered-image {
    max-width: 100%;
    max-height: 100%;
  }
  
