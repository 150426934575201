.left-container {
    position: relative;
    background-color: #003147;
    padding: 20px;
}

.title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
}