.ray_h5 {
    display: block;
    font-size: 0.83em;
    margin-block-start: 1.67em;

    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 800;
}

.ray_h4 {
    display: block;
    margin-block-start: 1.33em;

    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 800;
}