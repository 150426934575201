.ProductItems {
    width: 240px;
    height: 350px;
    margin: 5px;



}

.ProductItemListContainer {
    display: inline-flex;
}

.ProductItemListLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}