.horizontal-scroll,
.horizontal-scroll-border-bottom {
  margin: 30px;
}

.horizontal-scroll {
  overflow-x: scroll;
  overflow-y: hidden; /* Added to prevent vertical scrolling */
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.horizontal-scroll-content {
  display: inline-flex;
  padding-right: 20px;
}

.horizontal-scroll-border-bottom {
  border-bottom: 1px solid rgba(100, 100, 100, 0.6);
}

/* The scrollbar styles (optional) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
