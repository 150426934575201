.contactInfo {
    padding-top: 40px;
}

.contactInfo h5 {
    padding-left: 0px;
}

.contactInfo ul
{
    position: relative;
}

.contactInfo ul li
{
    position: relative;
    list-style: none;
    margin: 10px 0;
    /* cursor: pointer; */
}

.contactInfo ul li .icon
{
    display: inline-block;
    width: 30px;
    font-size: 18px;
    color: #03a9f4;
}

.contactInfo ul li span
{
    font-weight: 400;
    color: #fff;
}

.contactInfo ul li a
{
    font-weight: 300;
    color: #fff;

}

.contactInfo ul li a span
{
    
    text-decoration: none;
}