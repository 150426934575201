.right-container {
    background-color: #fff;
}

.title2
{
    color: #003147;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.about
{
    margin-bottom: 50px;
}

.about:last-child
{
    margin-bottom: 0;
}


.about .box
{
    display: flex;
    flex-direction: row;
    margin: 20px 0;
}

.about .box .year_company
{
    min-width: 180px;
}

.about .box .year_company .company_icon a img
{

    max-width: 80%;
    max-height: 60%;

}


.about .box .year_company h5
{
    text-transform: uppercase;
    color: #848c90;
    font-weight: 600;
    font-size: 14px;
}
.about .box .text h4
{
    text-transform: uppercase;
    color: #2a7da2;
    font-size: 16px;
    font-weight: 700 !important;
}

.about .skills .box .skill_type
{
    margin-right: 10px;
    min-width: 150px;
}
